<!-- =========================================================================================
  File Name: UserView.vue
  Description: User View page

========================================================================================== -->

<template>
  <div id="page-user-view">
    <vs-alert v-if="errorOccurred" color="danger" title="Course Not Found">
      <span>Course record with id: not found. </span>
      <span>
        <span>Check </span><router-link :to="{ name: 'admin-list-courses' }" class="text-inherit underline">All Courses</router-link>
      </span>
    </vs-alert>

    <div v-if="courseData" id="course-data">
      <!--- TOP COURSE BANNER START --->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <div
            class="rounded-lg w-full main-border"
            style="
              background-image: url(https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/global-banner.png);
              box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
              background-size: cover;
            "
          >
            <div class="knowledge-base-jumbotron-content rounded-lg p-4" style="backdrop-filter: blur(10px); height: 100%">
              <div class="flex justify-between items-center">
                <div class="lg:p-12 sm:p-10 p-8">
                  <h5 class="font-light text-white ml-1">Course Admin</h5>
                  <h1 class="text-white font-bold" style="font-size: 4rem">{{ courseData.name }}</h1>
                </div>
                <img v-if="courseData.thumbnail" :src="courseData.thumbnail" class="rounded-lg mr-4" width="150px" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--- TOP COURSE BANNER END --->

      <!--- COURSE ACTION BAR START --->
      <div class="vx-row mb-6 match-height">
        <div class="vx-col grid grid-cols-3 gap-8 w-full">
          <vx-card
            class="p-6 cursor-pointer text-white"
            @click="addStudentSearchActive = true"
            :style="`background-image: linear-gradient(118deg, rgba(var(--vs-success), 0.4), rgba(var(--vs-success), .2)), url(${cardBgImgURL})`"
          >
            <div class="flex items-center justify-center">
              <feather-icon icon="UserPlusIcon" svgClasses="h-16 w-16" />
            </div>
            <div class="flex items-center justify-center">
              <h2 class="text-center mt-4 text-white">Assign<br />Student</h2>
            </div>
          </vx-card>
          <vx-card
            class="p-6 cursor-pointer text-white"
            @click="subscriptionsManagerPopup = !subscriptionsManagerPopup"
            :style="`background-image: linear-gradient(118deg, rgba(var(--vs-primary), 0.4), rgba(var(--vs-primary), .2)), url(${cardBgImgURL})`"
          >
            <div class="flex items-center justify-center">
              <feather-icon icon="CreditCardIcon" svgClasses="h-16 w-16" />
            </div>
            <div class="flex items-center justify-center">
              <h2 class="text-center mt-4 text-white">Subscriptions<br />Manager</h2>
            </div>
          </vx-card>
          <vx-card
            class="p-6 cursor-pointer text-white"
            @click="$router.push(`/a/courses/${courseData.id}/mass`)"
            :style="`background-image: linear-gradient(118deg, rgba(var(--vs-danger), 0.4), rgba(var(--vs-danger), .2)), url(${cardBgImgURL})`"
          >
            <div class="flex items-center justify-center">
              <feather-icon icon="FileTextIcon" svgClasses="h-16 w-16" />
            </div>
            <div class="flex items-center justify-center">
              <h2 class="text-center mt-4 text-white">Generate<br />Report</h2>
            </div>
          </vx-card>
        </div>
      </div>
      <!--- COURSE ACTION BAR END --->

      <!--- COURSE SHOW INFORMATION START--->
      <div class="vx-row mb-6 match-height">
        <div class="vx-col w-1/3">
          <vx-card class="w-full flex items-center bg-dark">
            <h3 class="font-bold">Course Id</h3>
            <h3 class="font-light">{{ courseData.id }}</h3>
          </vx-card>
        </div>
        <div class="vx-col w-1/3">
          <vx-card class="w-full flex items-center bg-dark">
            <h3 class="font-bold">Course Status</h3>
            <h3 class="capitalize font-light">{{ courseData.status | capitalize }}</h3>
          </vx-card>
        </div>
        <div class="vx-col w-1/3">
          <vx-card class="p-1 bg-dark">
            <div class="grid grid-cols-2 gap-4 w-">
              <vs-button @click="editCoursePopup = !editCoursePopup" class="w-full" icon="icon-edit" icon-pack="feather">Edit </vs-button>
              <vs-button class="w-full" color="danger" icon="icon-trash" icon-pack="feather" @click="confirmDeleteCourse"
                >Archive
              </vs-button>
            </div>
          </vx-card>
        </div>
      </div>
      <!--- COURSE SHOW INFORMATION END--->

      <!-- COURSE BUILDER START --->
      <div v-if="true">
        <div v-if="courseData">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vx-card class="bg-dark">
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <img class="mr-4 rounded" :src="courseData.thumbnail" v-if="courseData.thumbnail" width="40" height="40" alt="" />
                    <h1 class="">Course Builder</h1>
                  </div>
                  <div class="flex justify-end items-center">
                    <feather-icon v-if="autoSave.status === 'success'" icon="CheckIcon" svgClasses="h-5 w-5 text-success mr-2" />
                    <div
                      v-else-if="autoSave.status === 'pending'"
                      class="vs-con-loading__container h-5 w-5 mr-2"
                      id="pendingLoadingCircle"
                    />
                    <feather-icon v-else icon="XCircleIcon" svgClasses="h-5 w-5 text-danger mr-2" />
                    <p class="cursor-pointer" @click="saveChanges">{{ autoSave.text }}</p>
                  </div>
                </div>
              </vx-card>
            </div>
          </div>

          <div class="main-border bg-dark rounded-lg p-1 mb-6">
            <vs-collapse-item class="p-0">
              <div slot="header" class="p-0">
                <h3 class="font-light">Apps & Compliance</h3>
              </div>
              <div class="vx-row">
                <div class="vx-col w-2/3">
                  <vx-card style="min-height: 30rem">
                    <div class="flex justify-between">
                      <h4>Enabled Apps</h4>
                      <vs-button @click="saveChanges('apps')" icon="icon-save" icon-pack="feather" size="small">Save Weights</vs-button>
                    </div>
                    <div>
                      <div class="rounded-lg pt-3">
                        <div class="grid grid-cols-1 2xl:grid-cols-2 gap-4">
                          <div v-for="(app, index) in courseData.appsEnabled" :key="app.id">
                            <div class="flex items-center justify-between gap-4 p-4 rounded-lg shadow-md" style="background-color: #040915">
                              <div class="flex items-center">
                                <img :src="app.icon" :alt="app.name" width="48" class="rounded-lg mr-4" />
                                <div class="mr-4">
                                  <h2>{{ app.name }}</h2>
                                  <p class="font-light" v-if="courseData.appsWeighting[app.id]">
                                    {{ courseData.appsWeighting[app.id].auto ? 'Auto Weighed' : '' }}
                                  </p>
                                </div>
                                <template v-if="courseData.appsWeighting[app.id]">
                                  <vs-input
                                    v-model="courseData.appsWeighting[app.id].weight"
                                    type="number"
                                    style="width: 75px"
                                    class="mr-3"
                                  />
                                  %
                                </template>
                              </div>
                              <div>
                                <feather-icon
                                  icon="XCircleIcon"
                                  @click="removeAppFromCourseConfirm(index)"
                                  svgClasses="w-10 h-10 text-danger cursor-pointer"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </vx-card>
                </div>
                <div class="vx-col w-1/3">
                  <vx-card class="mb-base">
                    <h4>Disabled Apps</h4>
                    <vs-list class="p-0 mt-3" v-if="appsListBuilder.length !== 0">
                      <vs-list-item
                        v-for="(listItem, index) in courseAppsDisabled"
                        :key="listItem.id"
                        :title="listItem.name"
                        :subtitle="listItem.id"
                      >
                        <vs-avatar slot="avatar" :src="listItem.thumbnail" class="mr-3" />
                        <vs-button
                          @click="
                            courseAppsDisabled.splice(index, 1);
                            courseData.appsEnabled.push(listItem);
                            saveChanges();
                          "
                          size="small"
                          icon="icon-plus"
                          icon-pack="feather"
                          color="success"
                          >Enable App</vs-button
                        >
                      </vs-list-item>
                    </vs-list>
                    <p class="mt-2" v-if="!courseAppsDisabled.length">All Platform Apps Have Been Enabled</p>
                  </vx-card>
                </div>
              </div>
            </vs-collapse-item>
          </div>

          <div class="main-border bg-dark rounded-lg p-1 mb-6">
            <vs-collapse-item class="p-0">
              <div slot="header" class="p-0">
                <h3 class="font-light">Simulations</h3>
              </div>
              <div class="vx-row">
                <div class="vx-col w-2/3">
                  <vx-card>
                    <div>
                      <div class="p-6 rounded-lg overflow-y-scroll">
                        <div v-if="!isAuthorizedSimulationsEmpty || dragging === true">
                          <h1 class="my-6 font-bold text-center" v-if="isAuthorizedSimulationsEmpty && dragging === true">
                            Drop Simulation Here
                          </h1>
                          <draggable
                            class="grid grid-cols-4 gap-5 cursor-move"
                            :list="courseData.authorizedSimulations"
                            :group="{ name: 'simulations' }"
                            @change="saveChanges"
                            style="min-height: 15rem"
                          >
                            <div
                              class="flex items-center justify-center cursor-move py-4"
                              v-for="(simulation, index) in courseData.authorizedSimulations"
                              :key="index"
                            >
                              <div class="p-1">
                                <div class="flex justify-center">
                                  <img :src="simulation.thumbnail" width="110" class="rounded-lg" alt="" />
                                </div>
                                <h5 class="font-bold text-center mt-3">{{ simulation.name }}</h5>
                                <div class="flex items-center justify-center mt-3">
                                  <vs-button
                                    color="primary"
                                    type="gradient"
                                    size="small"
                                    icon-pack="feather"
                                    icon="icon-edit"
                                    style="min-width: 120px"
                                    @click="$router.push(`/a/simulations/${simulation.id}`)"
                                    >Edit
                                  </vs-button>
                                </div>
                                <div class="flex items-center justify-center mt-3">
                                  <vs-button
                                    color="danger"
                                    type="gradient"
                                    size="small"
                                    icon-pack="feather"
                                    icon="icon-trash"
                                    style="min-width: 120px"
                                    @click="removeSimulationFromCourseConfirm(index)"
                                    >Remove
                                  </vs-button>
                                </div>
                              </div>
                            </div>
                          </draggable>
                        </div>
                        <div v-if="isAuthorizedSimulationsEmpty && dragging === false">
                          <div class="flex items-center justify-center">
                            <lottie-animation
                              path="https://assets3.lottiefiles.com/packages/lf20_1iNByG.json"
                              :loop="true"
                              :autoPlay="true"
                              :speed="1"
                              :width="300"
                            />
                          </div>
                          <h1 class="font-bold text-center mt-4">Its Lonely In Here</h1>
                          <h5 class="text-light text-center mt-2 mb-4">To Add Simulations Drag Them Into This Box</h5>
                        </div>
                      </div>
                    </div>
                  </vx-card>
                </div>
                <div class="vx-col w-1/3">
                  <vx-card class="mb-base">
                    <h4>Find Simulations</h4>
                    <vs-input
                      v-model="simulationsSearch"
                      @input="fetchSimulationsList"
                      class="my-4 mt-5 w-full"
                      placeholder="Search for Simulations"
                    />
                    <vs-list class="p-0 mt-3" v-if="simulationsList.length !== 0">
                      <draggable
                        :list="simulationsList"
                        :group="{ name: 'simulations', pull: 'clone', put: false }"
                        :sort="true"
                        :move="checkMoveSimulation"
                        class="cursor-move"
                        @start="dragging = true"
                        @end="dragging = false"
                      >
                        <vs-list-item v-for="listItem in simulationsList" :key="listItem.id" :title="listItem.name" :subtitle="listItem.id">
                          <vs-avatar slot="avatar" :src="listItem.thumbnail" class="mr-3" />
                        </vs-list-item>
                      </draggable>
                    </vs-list>
                    <p class="mt-2" v-else>No Records Found</p>
                  </vx-card>
                </div>
              </div>
            </vs-collapse-item>
          </div>

          <div class="main-border bg-dark rounded-lg p-1 mb-6">
            <vs-collapse-item class="p-0">
              <div slot="header" class="p-0">
                <h3 class="font-light">Labs</h3>
              </div>
              <div class="vx-row">
                <div class="vx-col w-2/3">
                  <vx-card>
                    <div>
                      <div class="p-6 rounded-lg overflow-y-scroll">
                        <div v-if="!isAuthorizedLabsEmpty || dragging === true">
                          <h1 class="my-6 font-bold text-center" v-if="isAuthorizedLabsEmpty && dragging === true">Drop Lab Here</h1>
                          <draggable
                            class="grid grid-cols-4 gap-5 cursor-move"
                            :list="courseData.authorizedLabs"
                            :group="{ name: 'labs' }"
                            @change="saveChanges"
                            style="min-height: 15rem"
                          >
                            <div
                              class="flex items-center justify-center cursor-move py-4"
                              v-for="(lab, index) in courseData.authorizedLabs"
                              :key="index"
                            >
                              <div class="p-1">
                                <div class="flex justify-center">
                                  <img :src="lab.thumbnail" width="110" class="rounded-lg" alt="" />
                                </div>
                                <h5 class="font-bold text-center mt-3">{{ lab.name }}</h5>
                                <div class="flex items-center justify-center mt-3">
                                  <vs-button
                                    color="primary"
                                    type="gradient"
                                    size="small"
                                    icon-pack="feather"
                                    icon="icon-edit"
                                    style="min-width: 120px"
                                    @click="$router.push(`/a/labs/properties/${lab.id}`)"
                                    >Edit
                                  </vs-button>
                                </div>
                                <div class="flex items-center justify-center mt-3">
                                  <vs-button
                                    color="danger"
                                    type="gradient"
                                    size="small"
                                    icon-pack="feather"
                                    icon="icon-trash"
                                    style="min-width: 120px"
                                    @click="removeLabFromCourseConfirm(index)"
                                    >Remove
                                  </vs-button>
                                </div>
                              </div>
                            </div>
                          </draggable>
                        </div>
                        <div v-if="isAuthorizedLabsEmpty && dragging === false">
                          <div class="flex items-center justify-center">
                            <lottie-animation
                              path="https://assets3.lottiefiles.com/packages/lf20_1iNByG.json"
                              :loop="true"
                              :autoPlay="true"
                              :speed="1"
                              :width="300"
                            />
                          </div>
                          <h1 class="font-bold text-center mt-4">Its Lonely In Here</h1>
                          <h5 class="text-light text-center mt-2 mb-4">To Add Labs Drag Them Into This Box</h5>
                        </div>
                      </div>
                    </div>
                  </vx-card>
                </div>
                <div class="vx-col w-1/3">
                  <vx-card class="mb-base">
                    <h4>Find Labs</h4>
                    <vs-input v-model="labsSearch" @input="fetchLabsList" class="my-4 mt-5 w-full" placeholder="Search for Labs" />
                    <vs-list class="p-0 mt-3" v-if="labsList.length !== 0">
                      <draggable
                        :list="labsList"
                        :group="{ name: 'labs', pull: 'clone', put: false }"
                        :sort="true"
                        :move="checkMoveLab"
                        class="cursor-move"
                        @start="dragging = true"
                        @end="dragging = false"
                      >
                        <vs-list-item v-for="listItem in labsList" :key="listItem.id" :title="listItem.name" :subtitle="listItem.id">
                          <vs-avatar slot="avatar" :src="listItem.thumbnail" class="mr-3" />
                        </vs-list-item>
                      </draggable>
                    </vs-list>
                    <p class="mt-2" v-else>No Records Found</p>
                  </vx-card>
                </div>
              </div>
            </vs-collapse-item>
          </div>

          <div class="main-border bg-dark rounded-lg p-1 mb-6">
            <vs-collapse-item class="p-0">
              <div slot="header" class="p-0">
                <h3 class="font-light">Study Domains</h3>
              </div>
              <div class="vx-row">
                <div class="vx-col w-2/3">
                  <vx-card>
                    <div>
                      <div class="mb-6 p-6 rounded-lg overflow-y-scroll">
                        <div v-if="!isAuthorizedStudyDomainsEmpty || dragging === true">
                          <h1 class="my-6 font-bold text-center" v-if="isAuthorizedStudyDomainsEmpty && dragging === true">
                            Drop Domain Here
                          </h1>
                          <draggable
                            class="grid grid-cols-3 gap-5 cursor-move"
                            :list="courseData.authorizedStudyDomains"
                            :group="{ name: 'study_domains' }"
                            @change="saveChanges"
                            style="min-height: 15rem"
                          >
                            <div
                              class="flex items-center justify-center cursor-move py-4"
                              v-for="(domain, index) in courseData.authorizedStudyDomains"
                              :key="index"
                            >
                              <div class="p-1">
                                <div class="flex justify-center">
                                  <img :src="domain.thumbnail" width="110" class="rounded-lg" alt="" />
                                </div>
                                <h5 class="font-bold text-center mt-3">{{ domain.name }}</h5>
                                <div class="flex items-center justify-center mt-3">
                                  <vs-button
                                    color="primary"
                                    type="gradient"
                                    size="small"
                                    icon-pack="feather"
                                    icon="icon-edit"
                                    style="min-width: 120px"
                                    @click="$router.push(`/a/study/domains/${domain.id}`)"
                                    >Edit
                                  </vs-button>
                                </div>
                                <div class="flex items-center justify-center mt-3">
                                  <vs-button
                                    color="danger"
                                    type="gradient"
                                    size="small"
                                    icon-pack="feather"
                                    icon="icon-trash"
                                    style="min-width: 120px"
                                    @click="removeStudySetFromCourseConfirm(index)"
                                    >Remove
                                  </vs-button>
                                </div>
                              </div>
                            </div>
                          </draggable>
                        </div>
                        <div v-if="isAuthorizedStudyDomainsEmpty && dragging === false">
                          <div class="flex items-center justify-center">
                            <lottie-animation
                              path="https://assets3.lottiefiles.com/packages/lf20_1iNByG.json"
                              :loop="true"
                              :autoPlay="true"
                              :speed="1"
                              :width="300"
                            />
                          </div>
                          <h1 class="font-bold text-center mt-4">Its Lonely In Here</h1>
                          <h5 class="text-light text-center mt-2 mb-4">To Add Study Domains Drag Them Into This Box</h5>
                        </div>
                      </div>
                    </div>
                  </vx-card>
                </div>
                <div class="vx-col w-1/3">
                  <vx-card class="mb-base">
                    <h4>Find Study Domains</h4>
                    <vs-input
                      v-model="studyDomainsSearch"
                      @input="fetchStudyDomainsList"
                      class="my-4 mt-5 w-full"
                      placeholder="Search for Domains"
                    />
                    <vs-list class="p-0 mt-3" v-if="studyDomainsList.length !== 0">
                      <draggable
                        :list="studyDomainsList"
                        :group="{ name: 'study_domains', pull: 'clone', put: false }"
                        :sort="true"
                        :move="checkMoveStudyDomain"
                        class="cursor-move"
                        @start="dragging = true"
                        @end="dragging = false"
                      >
                        <vs-list-item
                          v-for="listItem in studyDomainsList"
                          :key="listItem.id"
                          :title="listItem.name"
                          :subtitle="listItem.id"
                        >
                          <vs-avatar slot="avatar" :src="listItem.thumbnail" class="mr-3" />
                        </vs-list-item>
                      </draggable>
                    </vs-list>
                    <p class="mt-2" v-else>No Records Found</p>
                  </vx-card>
                </div>
              </div>
            </vs-collapse-item>
          </div>

          <div class="main-border bg-dark rounded-lg p-1 mb-6">
            <vs-collapse-item class="p-0">
              <div slot="header" class="p-0">
                <h3 class="font-light">ExamEdge Banks</h3>
              </div>
              <div class="vx-row">
                <div class="vx-col w-2/3">
                  <div class="w-full grid xl:grid-cols-2 gap-3" v-if="!isAuthorizedBanksEmpty">
                    <vx-card
                      v-for="(bank, index) in courseData.authorizedExamEdgeBanks"
                      :key="bank.id"
                      v-if="bank && typeof bank === 'object'"
                    >
                      <h4 class="font-bold">
                        Bank Name <span class="font-light">{{ bank.name }}</span>
                      </h4>
                      <h4 class="font-bold">
                        Bank Id <span class="font-light">{{ bank.id }}</span>
                      </h4>
                      <h4 class="font-bold mb-4">
                        Bank Questions <span class="font-light">{{ bank.questions.length }}</span>
                      </h4>
                      <vs-button @click="removeExamEdgeBankFromCourseConfirm(index)" icon="icon-trash-2" icon-pack="feather" color="danger"
                        >Remove Bank</vs-button
                      >
                    </vx-card>
                  </div>
                  <p v-else>No Bank Configured</p>
                </div>
                <div class="vx-col w-1/3">
                  <vx-card class="mb-base">
                    <h4>Find ExamEdge Banks</h4>
                    <vs-input
                      v-model="examEdgeBankSearch"
                      @input="fetchExamEdgeBanksList"
                      class="my-4 mt-5 w-full"
                      placeholder="Search for Banks"
                    />
                    <vs-list class="p-0 mt-3" v-if="examEdgeBankList.length !== 0">
                      <vs-list-item v-for="listItem in examEdgeBankList" :key="listItem.id" :title="listItem.name" :subtitle="listItem.id">
                        <vs-button
                          @click="
                            courseData.authorizedExamEdgeBanks.push(listItem);
                            saveChanges();
                          "
                          size="small"
                          icon="icon-plus"
                          icon-pack="feather"
                          color="success"
                          >Assign Bank</vs-button
                        >
                      </vs-list-item>
                    </vs-list>
                    <p class="mt-2 p-1" v-else>No Records Found</p>
                  </vx-card>
                </div>
              </div>
            </vs-collapse-item>
          </div>

          <div class="main-border bg-dark rounded-lg p-1 mb-6">
            <vs-collapse-item class="p-0">
              <div slot="header" class="p-0">
                <h3 class="font-light">Material Link</h3>
              </div>
              <div class="vx-row">
                <div class="vx-col w-1/3">
                  <vx-card>
                    <vs-input v-model="materialLink" class="w-full mb-2" placeholder="Material URL" />
                    <vs-button size="small" @click="saveChanges()">Attach Link</vs-button>
                  </vx-card>
                </div>
                <div class="vx-col w-2/3">
                  <iframe
                    v-if="courseData.materialLink"
                    :src="courseData.materialLink"
                    class="w-full rounded-lg main-border"
                    style="min-height: 500px"
                  ></iframe>
                </div>
              </div>
            </vs-collapse-item>
          </div>
        </div>
        <div class="simple-spinner" v-else>
          <span></span>
        </div>
      </div>

      <!-- COURSE BUILDER END --->

      <vs-popup :active.sync="addStudentSearchActive" :title="`Select User To Enroll In ${courseData.name}`">
        <vs-table :data="addStudentSearchResults" :sst="true" max-items="5" search @search="addStudentUserSearch">
          <template slot="header">
            <h3 class="ml-4">Users</h3>
          </template>

          <template slot="thead">
            <vs-th>Name</vs-th>
            <vs-th>Email</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr v-for="attach in data" :key="attach.id">
              <vs-td :data="`${attach.firstName} ${attach.lastName}`">
                {{ `${attach.firstName} ${attach.lastName}` }}
              </vs-td>

              <vs-td :data="attach.email">
                {{ attach.email }}
              </vs-td>

              <vs-td>
                <vs-button color="primary" size="small" type="border" @click="addStudentToCourseApps(attach)"> Assign </vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-popup>
      <vs-popup
        :active.sync="appSearchActive"
        title="Select Enabled Applications
      "
      >
        <vs-table :data="appSearchResults" :sst="true" max-items="5" search @search="addStudentAppsSearch" class="mb-2">
          <template slot="header">
            <h3 class="ml-4">Search Apps</h3>
          </template>

          <template slot="thead">
            <vs-th>Id</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr v-for="attach in data" :key="attach.id" v-if="isAppEnabled(attach.id)">
              <vs-td :data="attach.id">
                {{ attach.id }}
              </vs-td>

              <vs-td :data="`${attach.name}`">
                <div class="flex align-middle items-center">
                  <img :src="attach.icon" class="rounded mr-3" width="40px" />
                  {{ `${attach.name}` }}
                </div>
              </vs-td>

              <vs-td>
                <vs-button color="primary" size="small" type="border" @click="addStudentToCourseAssignApp(attach)"> Assign </vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-divider />
        <vs-table :data="appsSelected" max-items="10" class="mt-2">
          <template slot="header">
            <h3 class="ml-4 mt-4 mb-3">Enabled Apps</h3>
          </template>

          <template slot="thead">
            <vs-th>Id</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr v-for="(attach, index) in data" :key="attach.id">
              <vs-td :data="attach.id">
                {{ attach.id }}
              </vs-td>

              <vs-td :data="`${attach.name}`">
                <div class="flex align-middle items-center">
                  <img :src="attach.icon" class="rounded mr-3" width="40px" />
                  {{ `${attach.name}` }}
                </div>
              </vs-td>

              <vs-td>
                <vs-button color="primary" size="small" type="border" @click="appsSelected.splice(index, 1)"> Remove </vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="flex align-end">
          <vs-button color="primary" type="filled" class="mt-3" @click="addStudentToCourseCalendar">Next Step</vs-button>
        </div>
      </vs-popup>
      <vs-popup :active.sync="addStudentExpiryActive" title="Assign Course To User - Select Dates">
        <div class="mb-4">
          <h6>Choose Subscription Expiry</h6>
          <datepicker
            v-model="addStudentAssignCourseCalendar"
            :inline="true"
            class="datepicker-popup-inline mt-4"
            placeholder="Select Date"
          ></datepicker>
          <ul class="flex mt-5">
            <li>
              <vs-radio v-model="addStudentAssignCourseDuration" class="mr-4" vs-value="1" @input="calculateCalendarDate"
                >1 Month
              </vs-radio>
            </li>
            <li>
              <vs-radio v-model="addStudentAssignCourseDuration" class="mr-4" vs-value="3" @input="calculateCalendarDate"
                >3 Months
              </vs-radio>
            </li>
            <li>
              <vs-radio v-model="addStudentAssignCourseDuration" class="mr-4" vs-value="6" @input="calculateCalendarDate"
                >6 Months
              </vs-radio>
            </li>
            <li>
              <vs-radio v-model="addStudentAssignCourseDuration" class="mr-4" vs-value="12" @input="calculateCalendarDate"
                >1 Year
              </vs-radio>
            </li>
          </ul>
        </div>
        <p v-if="addStudentAssignCourseCalendar === null" class="text-danger font-bold">You Must Choose A Subscription Duration</p>

        <div class="flex align-end">
          <vs-button
            v-if="addStudentAssignCourseCalendar"
            color="primary"
            type="filled"
            @click="addStudentToCourseEnroll(addStudentAssignUser)"
            >Assign Student
          </vs-button>
        </div>
      </vs-popup>
      <vs-popup :active.sync="editCoursePopup" title="Edit Course">
        <div class="popup-input" v-if="courseData">
          <p class="mb-1 font-semibold">Name</p>
          <vs-input v-model="courseData.name" class="mb-3 w-full" />

          <p class="mb-1 font-semibold">Description</p>
          <vs-textarea v-model="courseData.description" height="100px" class="mb-3 w-full" />

          <p class="mb-1 font-semibold">Code</p>
          <vs-input v-model="courseData.code" class="mb-3 w-full" />

          <p class="mb-1 font-semibold">Thumbnail</p>
          <vs-input v-model="courseData.thumbnail" class="mb-3 w-full" />

          <p class="mb-1 font-semibold">Status</p>
          <div class="flex items-center mb-3">
            <vs-radio v-model="courseData.status" vs-name="courseStatus" vs-value="active" class="mr-4">Active </vs-radio>
            <vs-radio v-model="courseData.status" vs-name="courseStatus" vs-value="disabled" class="mr-4">Disabled </vs-radio>
          </div>
        </div>
      </vs-popup>
      <vs-popup :active.sync="subscriptionsManagerPopup" title="Subscriptions Manager" class="fs-h-auto" fullscreen>
        <list-subscriptions :course-id="courseId" :filter-courses="false" />
      </vs-popup>
    </div>
    <div class="simple-spinner" v-else>
      <span></span>
    </div>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import draggable from 'vuedraggable';
import LottieAnimation from '../../../../../components/lottie/LottieAnimation';
import ListSubscriptions from '../../../../student/subscriptions/admin/subscription-list/ListSubscriptions';

export default {
  data() {
    return {
      courseId: null,
      courseData: null,
      courseStructureExists: true,

      editCoursePopup: false,
      subscriptionsManagerPopup: false,

      appsListBuilder: [],
      appsListEnabled: [],

      simulationsList: [],
      simulationsSearch: '',

      examEdgeBankList: [],
      examEdgeBankSearch: '',

      labsList: [],
      labsSearch: '',

      studyDomainsList: [],
      studyDomainsSearch: '',

      testprepDomainsList: [],
      testprepDomainsSearch: '',

      materialLink: '',

      autoSave: {
        status: 'success',
        text: 'Ready To Save',
      },
      dragging: false,

      cardBgImgURL: 'https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/blue-wave-bg.png',

      addStudentSearchActive: false,
      addStudentExpiryActive: false,
      addStudentAppActive: false,
      addStudentSearchResults: [],
      addStudentAppResults: [],
      addStudentAssignUser: {},
      addStudentAssignApp: [],
      addStudentAssignCourseDuration: null,
      addStudentAssignCourseCalendar: null,

      appSearchActive: false,
      appSearchResults: [],
      appsSelected: [],

      statusOptions: ['active', 'hidden', 'disabled'],

      removeId: null,

      errorOccurred: false,
    };
  },
  components: {
    draggable,
    Prism,
    vSelect,
    Datepicker,
    LottieAnimation,
    ListSubscriptions,
  },
  computed: {
    courseAppsDisabled() {
      return this.appsListBuilder.filter((app) => !this.appsListEnabled.includes(app.id));
    },
    isAppsEnabledEmpty() {
      return this.courseData.apps_enabled.length === 0;
    },
    isAuthorizedSimulationsEmpty() {
      return this.courseData.authorizedSimulations.length === 0;
    },
    isAuthorizedBanksEmpty() {
      return this.courseData.authorizedExamEdgeBanks.length === 0;
    },
    isAuthorizedStudyDomainsEmpty() {
      return this.courseData.authorizedStudyDomains.length === 0;
    },
    isAuthorizedLabsEmpty() {
      return this.courseData.authorizedLabs.length === 0;
    },
  },
  methods: {
    addStudentAppsSearch(term) {
      this.$http.get(`platform/app?search=${term}&filter[id]=${this.appsListEnabled}&pageSize=5`).then((response) => {
        if (response.data) {
          this.appSearchResults = response.data.rows;
        }
      });
    },
    addStudentUserSearch(term) {
      this.$http.get(`user?search=${term}&pageSize=5`).then((response) => {
        if (response.data) {
          this.addStudentSearchResults = response.data.rows;
        }
      });
    },
    addStudentToCourseAssignApp(app) {
      let addApp = true;
      this.appsSelected.forEach((item) => {
        if (item.id === app.id) {
          addApp = false;
          return false;
        }
      });

      if (addApp === true) {
        this.appsSelected.push(app);
      } else {
        this.$vs.notify({
          title: 'App Already Assigned',
          text: 'The app you tried to assign has already been assigned',
          color: 'danger',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-alert-circle',
        });
      }
    },
    addStudentToCourseApps(user) {
      this.addStudentSearchActive = false;
      this.appSearchActive = true;
      this.addStudentAssignUser = user;
      this.addStudentAssignUser.avatar = this.addStudentAssignUser.avatar !== null ? this.addStudentAssignUser.avatar : 'default';
    },
    addStudentToCourseCalendar() {
      this.appSearchActive = false;
      this.addStudentExpiryActive = true;
    },
    addStudentToCourseEnroll(user) {
      this.$vs.loading();

      if (!this.addStudentAssignCourseCalendar) {
        this.$vs.notify({
          color: 'danger',
          title: 'Failed to create Subscription',
          text: 'Please provide subscription duration.',
          icon: 'feather',
          iconPack: 'icon-x-circle',
          position: 'top-right',
        });
        return this.$vs.loading.close();
      }

      const apps = [];
      this.appsSelected.forEach((app) => {
        apps.push(app.id);
      });

      const pl = {
        userId: user.id,
        courseId: this.courseData.id,
        apps,
      };

      pl.expiresAt = this.addStudentAssignCourseCalendar.getTime();

      this.$http
        .post('billing/subscription', pl)
        .then((response) => {
          this.$vs.loading.close();

          if (response.status === 200) {
            return this.$vs.notify({
              color: 'success',
              title: 'Subscription Created',
              text: 'You have successfully created a subscription',
              icon: 'feather',
              iconPack: 'icon-check-circle',
              position: 'top-right',
            });
          }
        })
        .catch((exception) => {
          console.dir(exception);
          this.$vs.loading.close();
          return this.$vs.notify({
            color: 'danger',
            title: 'Failed to create Subscription',
            text: exception.response.data.message,
            icon: 'feather',
            iconPack: 'icon-x-circle',
            position: 'top-right',
          });
        });

      this.addStudentExpiryActive = false;
      this.addStudentSearchActive = true;
    },
    calculateCalendarDate() {
      const date = new Date();
      const months = parseInt(this.addStudentAssignCourseDuration);
      date.setMonth(date.getMonth() + months);
      this.addStudentAssignCourseCalendar = date;
    },
    checkMoveLab(evt) {
      let move = true;
      this.courseData.authorizedLabs.forEach((lab) => {
        if (lab.id === evt.draggedContext.element.id) move = false;
      });
      return move;
    },
    checkMoveSimulation(evt) {
      let move = true;
      this.courseData.authorizedSimulations.forEach((simulation) => {
        if (simulation.id === evt.draggedContext.element.id) move = false;
      });
      return move;
    },
    checkMoveStudyDomain(evt) {
      let move = true;
      this.courseData.authorizedStudyDomains.forEach((domain) => {
        if (domain.id === evt.draggedContext.element.id) move = false;
      });
      return move;
    },
    confirmDeleteCourse() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Archive',
        text: 'Please confirm you want to archive this courses.',
        accept: this.deleteCourse,
        acceptText: 'Archive',
      });
    },
    deleteCourse() {
      this.$vs.loading();

      this.$http
        .delete(`courses/${this.$route.params.courseId}`)
        .then((response) => {
          this.$vs.loading.close();

          if (response.status === 200) {
            this.$router.push('/a/courses');

            return this.$vs.notify({
              color: 'success',
              title: 'Course Archived',
              text: 'You have successfully archived this courses',
              icon: 'feather',
              iconPack: 'icon-check-circle',
              position: 'top-right',
            });
          }
        })
        .catch((exception) => {
          this.$vs.loading.close();

          let error = 'An unknown error occurred while deleting this courses.';
          if (exception.response) {
            error = exception.response.data.message;
          }

          return this.$vs.notify({
            color: 'danger',
            title: 'Failed to Delete Group',
            text: error,
            icon: 'feather',
            iconPack: 'icon-x-circle',
            position: 'top-right',
          });
        });
    },
    fetchAppsListBuilder() {
      this.$http
        .get('platform/app?pageSize=20')
        .then((response) => {
          if (response.status === 200) {
            this.appsListBuilder = response.data.rows;
          }
        })
        .catch(() => {});
    },
    fetchExamEdgeBanksList() {
      this.$http
        .get(`examedge/bank?search=${this.examEdgeBankSearch}&pageSize=7`)
        .then((response) => {
          if (response.status === 200) {
            this.examEdgeBankList = response.data.rows;
          }
        })
        .catch(() => {});
    },
    fetchSimulationsList() {
      this.$http
        .get(`simulation?search=${this.simulationsSearch}&pageSize=7`)
        .then((response) => {
          if (response.status === 200) {
            this.simulationsList = response.data.rows;
          }
        })
        .catch(() => {});
    },
    fetchLabsList() {
      this.$http
        .get(`lab/activity?search=${this.labsSearch}&pageSize=7`)
        .then((response) => {
          if (response.status === 200) {
            this.labsList = response.data.rows;
          }
        })
        .catch(() => {});
    },
    fetchStudyDomainsList() {
      this.$http
        .get(`study/domain?search=${this.studyDomainsSearch}&pageSize=7`)
        .then((response) => {
          if (response.status === 200) {
            this.studyDomainsList = response.data.rows;
          }
        })
        .catch(() => {});
    },
    fetchTestPrepDomainsList() {
      this.$http
        .get(`examedge/domain?search=${this.studyDomainsSearch}&pageSize=5`)
        .then((response) => {
          if (response.status === 200) {
            this.testprepDomainsList = response.data.rows;
          }
        })
        .catch(() => {});
    },
    fetchInit() {
      this.courseId = this.$route.params.courseId;
      this.fetchCourseDetails();
      this.fetchAppsListBuilder('');
      this.fetchSimulationsList('');
      this.fetchExamEdgeBanksList('');
      this.fetchStudyDomainsList('');
      this.fetchTestPrepDomainsList('');
      this.fetchLabsList('');
      this.addStudentUserSearch('');
    },
    fetchCourseDetails() {
      this.$http
        .get(
          `course/${this.courseId}?populate=authorizedSimulations,authorizedLabs,authorizedStudyDomains,authorizedExamEdgeBanks,appsEnabled`,
        )
        .then((response) => {
          if (response.status === 200) {
            this.courseData = response.data;
            this.appsListEnabled = this.courseData.appsEnabled.map((app) => app.id);
            this.materialLink = this.courseData.materialLink;

            this.addStudentAppsSearch('');
          }
        })
        .catch((error) => {
          this.errorOccurred = true;
          this.error_msg = error;
        });
    },
    isAppEnabled(id) {
      return !this.appsSelected.some((app) => app.id === id);
    },
    refreshCourseCache(id) {
      this.$vs.loading();

      this.$http
        .get(`courses/${id}/cache`)
        .then((response) => {
          this.$vs.loading.close();

          if (response.status === 200) {
            return this.$vs.notify({
              color: 'success',
              title: 'Cache Cleared',
              text: 'The courses cache has been cleared successfully',
              icon: 'feather',
              iconPack: 'icon-check-circle',
              position: 'top-right',
            });
          }
        })
        .catch(() => {
          this.$vs.loading.close();
          return this.$vs.notify({
            color: 'danger',
            title: 'Failed to Clear Cache',
            text: 'The courses cache was not cleared successfully',
            icon: 'feather',
            iconPack: 'icon-x-circle',
            position: 'top-right',
          });
        });
    },
    removeAppFromCourseConfirm(index) {
      this.removeId = index;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Remove App From Course',
        text: 'Please confirm you want remove this app from the course.',
        accept: this.removeAppFromCourse,
        acceptText: 'Remove',
      });
    },
    removeAppFromCourse() {
      if (this.removeId === null) return;
      this.courseData.appsEnabled.splice(this.removeId, 1);
      this.saveChanges();
      this.removeId = null;
    },
    removeSimulationFromCourseConfirm(index) {
      this.removeId = index;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Remove Simulation From Course',
        text: 'Please confirm you want remove this simulation from the course.',
        accept: this.removeSimulationFromCourse,
        acceptText: 'Remove',
      });
    },
    removeSimulationFromCourse() {
      if (this.removeId === null) return;
      this.courseData.authorizedSimulations.splice(this.removeId, 1);
      this.saveChanges();
      this.removeId = null;
    },
    removeLabFromCourseConfirm(index) {
      this.removeId = index;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Remove Lab From Course',
        text: 'Please confirm you want remove this lab from the course.',
        accept: this.removeLabFromCourse,
        acceptText: 'Remove',
      });
    },
    removeLabFromCourse() {
      if (this.removeId === null) return;
      this.courseData.authorizedLabs.splice(this.removeId, 1);
      this.saveChanges();
      this.removeId = null;
    },
    removeStudySetFromCourseConfirm(index) {
      this.removeId = index;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Remove Set From Course',
        text: 'Please confirm you want remove this set from the course.',
        accept: this.removeStudySetFromCourse,
        acceptText: 'Remove',
      });
    },
    removeExamEdgeBankFromCourse() {
      if (this.removeId === null) return;
      this.courseData.authorizedExamEdgeBanks.splice(this.removeId, 1);
      this.saveChanges();
      this.removeId = null;
    },
    removeExamEdgeBankFromCourseConfirm(index) {
      this.removeId = index;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Remove Bank From Course',
        text: 'Please confirm you want remove this bank from the course.',
        accept: this.removeExamEdgeBankFromCourse,
        acceptText: 'Remove',
      });
    },
    async saveChanges(type) {
      const pl = {};

      this.courseData.materialLink = this.materialLink;

      for (const [key, value] of Object.entries(this.courseData)) {
        const allowedKeys = [
          'authorizedSimulations',
          'authorizedLabs',
          'authorizedStudyDomains',
          'appsEnabled',
          'authorizedExamEdgeBanks',
          'materialLink',
        ];
        if (!allowedKeys.includes(key)) continue;

        if (key === 'materialLink') {
          pl.materialLink = value;
          continue;
        }

        pl[key] = [];
        value.forEach((item) => {
          pl[key].push(item.id);
        });
      }

      if (type === 'apps') {
        pl.appsWeighting = this.courseData.appsWeighting;
      }

      // if (this.courseData.authorizedExamEdgeBank) {
      //   pl.authorizedExamEdgeBank = this.courseData.authorizedExamEdgeBank.id;
      // }

      // if (this.courseData.authorizedExamEdgeBank === null) {
      //   pl.authorizedExamEdgeBank = null;
      // }

      this.autoSave = {
        text: 'Saving...',
        status: 'pending',
      };

      setTimeout(() => {
        this.$vs.loading({
          color: '#FF9F43',
          background: 'transparent',
          container: '#pendingLoadingCircle',
          scale: 0.325,
          type: 'point',
        });
      }, 1);

      await this.$http
        .patch(`course/${this.courseData.id}`, pl)
        .then(async () => {
          await this.fetchCourseDetails();

          setTimeout(() => {
            this.$vs.loading.close('#pendingLoadingCircle> .con-vs-loading');
            const date = new Intl.DateTimeFormat('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            });
            this.autoSave = {
              text: `All Changes Saved - ${date.format(new Date())}`,
              status: 'success',
            };
          }, 750);
        })
        .catch(() => {
          this.$vs.loading.close('#pendingLoadingCircle> .con-vs-loading');
          this.autoSave = {
            text: 'Error While Saving Changes',
            status: 'error',
          };
        });
    },
    updateCourse() {
      const pl = {
        ...this.courseData,
        authorizedSimulations: undefined,
        authorizedLabs: undefined,
        authorizedStudyDomains: undefined,
        authorizedExamEdgeBank: undefined,
        authorizedExamEdgeBanks: undefined,
        appsEnabled: undefined,
        appsWeighting: undefined,
      };
      this.$http
        .patch(`course/${this.courseId}`, pl)
        .then((response) => {
          if (response.status === 200) {
            this.$vs.notify({
              title: 'Course Updated Successfully',
              text: `${this.courseData.name} has been updated.`,
              color: 'success',
              position: 'bottom-right',
              iconPack: 'feather',
              icon: 'icon-check-circle',
            });
          }
        })
        .catch((exception) => {
          let error = 'An unknown error occurred while updating this course';
          if (exception.response) {
            error = exception.response.data.message;
          }
          this.$vs.notify({
            title: 'Failed To Update Course',
            text: error,
            color: 'danger',
            position: 'bottom-right',
            iconPack: 'feather',
            icon: 'icon-x-circle',
          });
        });
    },
  },
  created() {
    this.fetchInit();
  },
  watch: {
    editCoursePopup(val) {
      if (!val) this.updateCourse();
    },
  },
};
</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }

  @media (min-width: 1200px) and (max-width: 1700px) {
    .image-course {
      display: none;
    }
    .course-col {
      width: 0 !important;
      min-height: 2.05vh;
    }
    .welcome-col {
      width: 100% !important;
    }
  }
}
</style>
